import React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


export default function DescriptionParagraph(props) {
    return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: "100%"
        },
      }}
    >
      <Paper elevation={2}>
        <Typography variant="subtitle1" component="p" margin={2}>
        The JVNC (Jubilee Voices North Carolina) Loop Library is a repository of homemade percussion loops that we use as accompaniment tracks during worship ministrations.  The purpose or intent of this repository is to have a central location where vocalists and musicians can have access to loops when planning worship sets or looking for loops to practice with.  Most of these loops are only a few seconds in length and are intended to be downloaded and imported into a DAW (e.g. Ableton Live, Logic Pro, etc.) in order to loop them for use in worship.
        </Typography>
      </Paper>
    </Box>
    );
}