import './App.css';
import Container from '@mui/material/Container';
import MediaControlCard from './components/MyCardMedia';
import { BottomNavigation, Grid, Pagination } from '@mui/material';
import SearchAppBar from './components/AppBar';
import loops from './data.json';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import DescriptionParagraph from './components/DescriptionParagraph';

const searchLoops = (query) => {
  const filteredLoops = loops.filter((loop) => {
    const loopName = loop.name && loop.name.toLowerCase();
    const [artistFirstName, artistLastName] = loop.artist && loop.artist.split(' ');
    const bpm = loop.bpm;

    return loopName.startsWith(query.toLowerCase()) || 
           artistFirstName && artistFirstName.toLowerCase().startsWith(query.toLowerCase()) || 
           artistLastName && artistLastName.toLowerCase().startsWith(query.toLowerCase()) ||
           bpm.toString().startsWith(query);
  });

  return filteredLoops;
};


function App() {
  const [filteredLoops, setFilteredLoops] = useState(loops);
  const [query, setQuery] = useState('');
  const [pageSize, setPageSize] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const numPages = Math.ceil(filteredLoops.length / pageSize);
  

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const handleSearch = (event) => {
    const value = event.target.value;
    setQuery(value);
    const filteredLoops = searchLoops(value);
    if (filteredLoops.length > 0 || query === '') {
      setCurrentPage(1);
    }
    setFilteredLoops(filteredLoops);
  };

  return (
    <div className="App">
      <SearchAppBar searchHandler={handleSearch}/>

     <Box sx={{backgroundColor: "#f5f5f5"}}>
      <Container sx={{marginY: 0}}>

       <DescriptionParagraph/>

          {/* Loop Cards */}
          <Grid container spacing={5} justifyContent="center" alignItems="center">
          {filteredLoops.slice(indexOfFirstItem, indexOfLastItem).map((loop) => (
            <MediaControlCard loop={loop}/>
          ))}
          </Grid>
      </Container>

      {/* Pagination Footer */}
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation>
        <Stack alignItems="center" sx={{marginY: 1.5}}>
          <Pagination count={numPages} defaultPage={1} page={currentPage} variant="outlined" color="primary" onChange={handlePageChange}/>
        </Stack>
      </BottomNavigation>
      </Paper>
      {/* End Pagination Footer */}
      </Box>
    </div>
  );
}

export default App;
