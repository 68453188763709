import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  components: {
      MuiTypography: {
          variants: [
              {
                  props: { variant: 'body2' },
                  style: { fontSize: 11 }
              },
              {
                  props: { variant: 'body3' },
                  style: { fontSize: 9 }
              }
          ]
      }
  }
})


export default function MediaControlCard(props) {
  return (
    <ThemeProvider theme={theme}>
    <Grid item xs={5}>
    <Card sx={{ display: 'flex' }} elevation={6}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6">
            {props.loop.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {props.loop.artist}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            {props.loop.bpm} BPM
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1, width: 300}}>
          <audio className="audio-element" controls /*controlsList="nodownload"*/ src={props.loop.audio_file} />
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 120, height: 120, margin: 2 }}
        image={props.loop.album_art}
        alt={props.loop.album}
      />
    </Card>
    </Grid>
    </ThemeProvider>
  );
}